<template>
  <img v-tooltip="tooltip" :src="source" :alt="$t('components.squaredIcon.iconAltText')" :class="classes" />
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    source: string;
    size?: 'l' | 'm' | 's' | 'xl' | 'xxl';
    frame?: 'none' | 'thick' | 'thin';
    rounded?: boolean;
    tooltip?: Record<string, unknown> | string;
  }>(),
  {
    size: 'l',
    frame: 'none',
    tooltip: undefined,
  }
);

const size = computed(() => {
  switch (props.size) {
    case 'xxl':
      return ['w-[7.25rem]', ' h-[7.25rem]'];
    case 'xl':
      return ['w-[4.75rem] ', ' h-[4.75rem]'];
    case 'l':
      return ['w-48 ', ' h-48'];
    case 'm':
      return ['w-24', ' h-24'];
    case 's':
      return ['w-16', ' h-16'];
  }
});

const roundness = computed(() => {
  if (props.rounded) return ['rounded-full'];

  switch (props.size) {
    case 'xxl':
    case 'xl':
    case 'l':
      return ['rounded-8'];
    case 'm':
    case 's':
      return ['rounded-4'];
  }
});

const frame = computed(() => {
  switch (props.frame) {
    case 'thick':
      return ['border-2', 'border-white', ' dark:border-black'];
    case 'thin':
      return ['border', 'border-white', ' dark:border-black'];
    case 'none':
      return [''];
  }
});

const classes = computed(() => {
  return [...size.value, ...frame.value, ...roundness.value];
});
</script>
